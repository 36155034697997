import React from 'react'
import {ButtonGroup, Row, Col} from "react-bootstrap";
import User from "./../User";
import Credit from "./../Credit";
import CreditMobile from "./../Credit/Mobile";
import Logo from "./../Logo";
import Favicon from "./../Logo/favicon";
import Chat from "./../Chat";
import RealTime from "./../RealTime";
import MobileMenu from "./../Menu/MobileMenu";

import Wallet from "../../../Components/User/Wallet";
import Notifications from "./../Notification";
import {isMobile, Event, BRAND} from "../../../../Helper";

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            header: null,
            show: true
        }
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize(){
        if(!isMobile()){
            this.setState({ header: this.desktop() })
        }
        else {
            this.setState({ header: this.mobile() })
        }
    }

    collapse = () =>{
        this.setState({ show: !this.state.show })
        Event.emit('toggle_sidebar', this.state.show);
    }

    desktop = () => {
        const { t } = this.props;
        return (
            <div className="d-flex justify-content-between logged-in-user" id={'topbar2'}>
                <div className='d-flex justify-content-left'>
                <Logo brand={BRAND} /><Wallet t={t} /><a href='tel:0716797777' className='phone-header'><span><i className='mdi mdi-phone mr-2 '></i></span></a>
                </div>
              
            <div className='d-flex justify-content-right'>
            <Credit t={t} /> <User t={t}/>
            </div>
            
        </div>

           
        );
    }

    mobile = () => {
        const { t } = this.props;
        return (
            <div className="d-flex justify-content-between" id={'topbar2'}>
                <div className='d-flex justify-content-left'>
                <Favicon brand={BRAND} /> <Wallet t={t} />  <a href='tel:0716797777' className='phone-header-mobile'><span><i className='mdi mdi-phone'></i></span></a>
                </div>
                
                            
                            <div className='d-flex justify-content-right'>
                            <CreditMobile t={t} /><RealTime t={t} />
                            <User t={t}/>
                            </div>
                            
                        </div>
                
        );
    }

    render() {
        return this.state.header;
    }
}

export default Header;